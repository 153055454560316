import React from 'react';

import { ConfirmModal } from '@grafana/ui';

import { ConfirmModalLoader } from '../ConfirmationModalLoader';
import { PatternRecommendation } from '@/api/types';
import { useRecommendations, useUpdateRecommendationsMutation } from '@/hooks/api-hooks';
import { useModifiedDropRates } from '@/hooks/context-hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function ApplyModal({ isOpen, onClose }: Props) {
  const { data: recommendations, isFetching: recommendationsIsFetching } = useRecommendations();
  const { modifiedDropRates, setModifiedDropRates } = useModifiedDropRates();
  const { isPending: recommendationsUpdating, mutateAsync: updateRecommendationsAsync } =
    useUpdateRecommendationsMutation();

  const onConfirm = async () => {
    const newRecs = recommendations.items.map((recommendation) => {
      const modified = modifiedDropRates.get(recommendation.pattern);
      if (!modified) {
        return recommendation;
      }

      const value = modified.rate === undefined ? recommendation.configured_drop_rate : Number(modified.rate);
      const locked = modified?.locked === undefined ? recommendation.locked : modified.locked;

      return { ...recommendation, configured_drop_rate: value, locked } as PatternRecommendation;
    });

    await updateRecommendationsAsync(newRecs);
    setModifiedDropRates(new Map());
  };

  const busy = recommendationsUpdating || recommendationsIsFetching;

  const getConfirmButtonText = (isLoading?: boolean) => {
    return isLoading ? ((<ConfirmModalLoader text="Applying..." />) as unknown as string) : 'Apply';
  };

  const noop = () => {
    return;
  };

  return (
    <ConfirmModal
      title="Apply drop rates"
      isOpen={isOpen}
      onDismiss={busy ? noop : onClose}
      confirmText={getConfirmButtonText(busy)}
      dismissText="Cancel"
      onConfirm={async () => {
        await onConfirm();
        onClose();
      }}
      body="Applying the current drop rates will reduce the volume of logs and may impact future queries. Are you sure you want to apply changes?"
    />
  );
}
