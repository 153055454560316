import React, { useMemo } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, Tooltip, useTheme2 } from '@grafana/ui';

import { useRecommendations } from '@/hooks/api-hooks';
import { useModifiedDropRates } from '@/hooks/context-hooks';

export function PatternModifiedCount() {
  const styles = useStyles(useTheme2());

  const { data: recommendations } = useRecommendations();
  const { modifiedDropRates } = useModifiedDropRates();
  const modifiedRecommendationCount = modifiedDropRates.size;

  const duplicateEntryCount = recommendations?.duplicateItems.length;

  const uniquePatternCount = recommendations?.items.length;

  const error = useMemo(() => {
    if (duplicateEntryCount === undefined || duplicateEntryCount === 0) {
      return null;
    }
    return (
      <Tooltip
        content={
          `The fetched recommendations have ${duplicateEntryCount} duplicate pattern identifiers. ` +
          `Only the first entry of a duplicate pattern will be displayed and operated on. ` +
          `This may result in the display of incorrect recommendations or other information for certain patterns. ` +
          `Please refresh your browser and raise an issue with Support if this does not automatically resolve itself.`
        }
      >
        <Icon name="exclamation-triangle" />
      </Tooltip>
    );
  }, [duplicateEntryCount]);

  const content = useMemo(() => {
    if (modifiedRecommendationCount === 0) {
      return undefined;
    }

    const messageContent =
      modifiedRecommendationCount === 1 ? (
        <>{modifiedRecommendationCount} pattern changed</>
      ) : (
        <>{modifiedRecommendationCount} patterns changed</>
      );

    const message = <span>{messageContent}</span>;

    const tooltip =
      `There are ${uniquePatternCount} patterns, and ${modifiedRecommendationCount} of these have had their drop rates modified, or their locked-state modified. ` +
      `These modifications will be saved if you click on "Apply drop rates."`;

    return { message, tooltip };
  }, [uniquePatternCount, modifiedRecommendationCount]);

  if (!content) {
    return <div className={styles.count}>{error}</div>;
  }

  const { message, tooltip } = content;

  return (
    <div className={styles.count}>
      <Tooltip content={tooltip}>{message}</Tooltip>&nbsp;{error}
    </div>
  );
}

function useStyles(theme: GrafanaTheme2) {
  return {
    count: css({
      margin: theme.spacing(0.5),
      whiteSpace: 'nowrap',
    }),
  };
}
