import React, { useMemo } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Modal, useStyles2 } from '@grafana/ui';

import { PatternRecommendation } from '../../api/types';
import { Bytes } from '../Bytes';
import { QueryResultHeader } from '../QueryResultHeader';
import { SavingsPreviewPanels } from './SavingsPreviewPanels';
import { getAbsoluteTotalVolume, getSummaryVolumes } from './utils';
import { useMetrics } from '@/hooks/api-hooks';
import { useModifiedDropRates } from '@/hooks/context-hooks';
import { formatNumber } from '@/utils/formats';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  recommendations: PatternRecommendation[];
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    content: css({
      textAlign: 'left',
    }),
  };
};

export function SummaryModal({ isOpen, onClose, recommendations }: Props) {
  const styles = useStyles2(getStyles);
  const { modifiedDropRates } = useModifiedDropRates();
  const { data: metrics, error: metricsError, isError: metricsIsError, isLoading: metricsIsLoading } = useMetrics();

  const [relativeTotalVolume, relativeAfterDrop] = useMemo(() => {
    const recommendationToSummary: PatternRecommendation[] = recommendations.map((recommendation) => {
      if (modifiedDropRates.has(recommendation.pattern) && modifiedDropRates.get(recommendation.pattern)?.rate) {
        const updatedRate = modifiedDropRates.get(recommendation.pattern)?.rate;

        if (!updatedRate) {
          return recommendation;
        }

        return { ...recommendation, configured_drop_rate: +updatedRate };
      } else {
        return recommendation;
      }
    });

    return getSummaryVolumes(recommendationToSummary);
  }, [modifiedDropRates, recommendations]);

  const absoluteTotalVolume = useMemo(() => getAbsoluteTotalVolume(metrics), [metrics]);

  const projectedSavings = relativeTotalVolume - relativeAfterDrop;

  const beforeDrops = absoluteTotalVolume !== null ? absoluteTotalVolume : relativeTotalVolume;
  const afterDrops = absoluteTotalVolume !== null ? absoluteTotalVolume - projectedSavings : relativeAfterDrop;

  const difference = useMemo(() => {
    if (projectedSavings < 0) {
      const percentIncrease = (-projectedSavings / beforeDrops) * 100;
      return (
        <>
          {`a projected volume increase of ${formatNumber(percentIncrease, 2) + '%'}`} (
          <Bytes bytes={-projectedSavings} decimals={2} />)
        </>
      );
    }

    const percentSavings = (projectedSavings / beforeDrops) * 100;
    return (
      <>
        {`a projected savings of ${formatNumber(percentSavings, 2) + '%'}`} (
        <Bytes bytes={projectedSavings} decimals={2} />)
      </>
    );
  }, [projectedSavings, beforeDrops]);

  if (metricsIsLoading || !metrics) {
    return (
      <QueryResultHeader errors={[metricsError]} isErrorArr={[metricsIsError]} isLoadingArr={[metricsIsLoading]} />
    );
  }

  return (
    <Modal title="Savings preview" isOpen={isOpen} onDismiss={() => onClose()}>
      <div className={styles.content}>
        The impact of the drop rates you have selected based on ingestion over the last 15 days will result in{' '}
        {difference}. See the volume comparison before and after drops:
      </div>
      <SavingsPreviewPanels ingestVolume={beforeDrops} projectedVolume={afterDrops} />
      <Modal.ButtonRow>
        <Button variant="secondary" onClick={() => onClose()}>
          Close
        </Button>
      </Modal.ButtonRow>
    </Modal>
  );
}
