import React, { useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { inRange as lodashInRage } from 'lodash';

import { GrafanaTheme2, IconName } from '@grafana/data';
import { IconButton, InlineField, Input, PopoverContent, Tooltip, useStyles2 } from '@grafana/ui';

import { PatternRecommendation } from '@/api/types';
import { useRecommendations } from '@/hooks/api-hooks';
import { useModifiedDropRates } from '@/hooks/context-hooks';
import { getComparisonWithRecommendation } from '@/types/style';
import { DROP_RATE_UPPER_LIMIT_EXCLUSIVE } from '@/utils/constants';

interface Props {
  recommendation: PatternRecommendation;
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css({
      alignItems: 'center',
      display: 'flex',
    }),
    inputBox: css({
      verticalAlign: 'top',
    }),
    inputBoxModified: {
      default: css({
        border: `1px solid ${theme.colors.success.border}`,
      }),
      recommendedIsLess: css({
        border: `1px solid ${theme.colors.warning.border}`,
      }),
      // If the input box is modified, but in an error state,
      // the browser will indicate via the `invalid` property on the inline field.
    },
    lockField: css({
      lineHeight: 2.5,
    }),
    lockIcon: css({
      color: 'rgba(194, 109, 49, 1)',
    }),
  };
};

export const CurrentRateEditor = ({ recommendation }: Props) => {
  const styles = useStyles2(getStyles);
  const { modifiedDropRates, setModifiedDropRates } = useModifiedDropRates();
  // This keeps track if the lock has been clicked on, in case it clashes with the 'onBlur' event
  // TODO We shouldn't need this if we are using onChange
  const [mouseDownOnLock, setMouseDownOnLock] = useState(false);
  const { configured_drop_rate, locked: originalLocked, pattern, recommended_drop_rate } = recommendation;
  const { data: recommendations } = useRecommendations();

  /**
   * TODO there are a lot of variable creation and check condition here as
   * well as the other file, which is hard to maintain and navigate around.
   * Noted this down for future PR after the refactor of context
   */
  const modified = modifiedDropRates.get(pattern);
  const displayedRate = modified?.rate || configured_drop_rate;
  const modifiedRateAsNumber = Number(modified?.rate);
  const locked = modified?.locked === undefined ? originalLocked : modified.locked;
  const rateIsModified = modified?.rate && configured_drop_rate !== modifiedRateAsNumber;
  const displayedRateAsNumber = Number(displayedRate);
  const rateOutOfRange = !lodashInRage(displayedRateAsNumber, DROP_RATE_UPPER_LIMIT_EXCLUSIVE);

  const onDropRateChange = ({ currentTarget }: React.FocusEvent<HTMLInputElement, Element>) => {
    /**
     * TODO same as above TODO comment, we have a lot of variable pieces here tracking a piece of
     * feature which we probably can reduce and make it simpler
     */

    let forceLock: boolean | undefined;
    if (mouseDownOnLock) {
      forceLock = !locked;
    }
    const currentLocked = forceLock !== undefined ? forceLock : modifiedDropRates.get(pattern)?.locked;

    const originalRecommendation = recommendations.mappedItems?.get(pattern);
    const lockedUnchanged = currentLocked === undefined || currentLocked === originalRecommendation?.locked;
    const rateUnchanged =
      currentTarget.value.trim() === '' || Number(currentTarget.value) === originalRecommendation?.configured_drop_rate;

    if (lockedUnchanged && rateUnchanged) {
      modifiedDropRates.delete(pattern);
      setModifiedDropRates(new Map([...modifiedDropRates]));
    } else {
      setModifiedDropRates(new Map([...modifiedDropRates, [pattern, { rate: currentTarget.value }]]));
    }
  };

  const onLockButtonClick = () => {
    const rate = modifiedDropRates.get(pattern)?.rate;

    /**
     * TODO the comparison of locked and originalLocked should be locked === originalLocked
     * instead of !==, the reason this is working is locked is set to modified.locked or originalLocked
     * in line 63 causing the locked storing the prevState instead of currentState. I will leave this
     * as it is for now because we will have a completely different design for the lock -> Exemption
     *  */
    if (modifiedDropRates.has(pattern) && locked !== originalLocked && !rate) {
      modifiedDropRates.delete(pattern);
      setModifiedDropRates(new Map([...modifiedDropRates]));
    } else {
      setModifiedDropRates(new Map([...modifiedDropRates, [pattern, { locked: !locked, rate }]]));
    }
  };

  const inputClassKey = useMemo(() => {
    const comparison = getComparisonWithRecommendation(recommended_drop_rate, modifiedRateAsNumber);
    if (!comparison) {
      return undefined;
    }
    if (comparison !== 'recommendedIsLess') {
      return 'default';
    }
    return comparison;
  }, [recommended_drop_rate, modifiedRateAsNumber]);

  const inputClassName = inputClassKey ? styles.inputBoxModified[inputClassKey] : undefined;
  const icon: IconName = locked ? 'lock' : 'unlock';

  const currentRateComponents = (
    <div className={styles.container}>
      <InlineField invalid={rateOutOfRange} error={'Drop percentage must be between 0 and 100%'} disabled={locked}>
        <Input
          data-testid="configured-drop-rate"
          className={cx(inputClassName, styles.inputBox)}
          maxLength={3}
          defaultValue={displayedRate}
          width={8}
          type="number"
          onBlur={onDropRateChange}
        />
      </InlineField>
      <InlineField>
        <IconButton
          className={locked ? styles.lockIcon : ''}
          data-testid={`icon-${icon}`}
          aria-label="pattern-lock"
          name={icon}
          onClick={onLockButtonClick}
          onMouseDown={() => setMouseDownOnLock(true)}
          onMouseUp={() => setMouseDownOnLock(false)}
          size="lg"
        />
      </InlineField>
    </div>
  );

  if (rateOutOfRange) {
    // Do not bother with tooltips if we have a visible error state.
    return currentRateComponents;
  }

  const tooltipContent: PopoverContent = () => {
    const activeRate = originalLocked ? (
      <div>{`This pattern's drop rate is saved at ${configured_drop_rate}% and locked.`}</div>
    ) : (
      <div>{`This pattern's drop rate is saved at ${configured_drop_rate}%.`}</div>
    );

    if (!rateIsModified) {
      if (modified?.locked === undefined) {
        return activeRate;
      }

      const lockMessage = modified.locked
        ? 'Applying this change will lock the rate at that value.'
        : 'Applying this change will unlock the rate so it can be changed.';

      return (
        <>
          {activeRate}
          <div>{lockMessage}</div>
        </>
      );
    }

    if (modified.locked) {
      return (
        <>
          {activeRate}
          <div>This will change the drop rate to {modifiedRateAsNumber}% and lock it at that value.</div>
        </>
      );
    }

    return (
      <>
        {activeRate}
        <div>This will change the drop rate to {modifiedRateAsNumber}%.</div>
      </>
    );
  };

  return <Tooltip content={tooltipContent}>{currentRateComponents}</Tooltip>;
};
