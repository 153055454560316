import { useCallback, useEffect, useMemo, useState } from 'react';

import copy from 'copy-to-clipboard';

import { useRecommendations } from '@/hooks/api-hooks';
import { useFilter } from '@/hooks/context-hooks';

type ReccomendationFilterCounts = {
  recommendationsFiltered?: number;
  recommendationsLoaded?: number;
};

export function useRecommendationFilterCounts(): ReccomendationFilterCounts {
  const { filterCheck } = useFilter();

  const { data } = useRecommendations();

  return {
    recommendationsFiltered: filterCheck?.size,
    recommendationsLoaded: data?.items.length,
  };
}

export function useRecommendationFilterCountMessage() {
  const { recommendationsFiltered, recommendationsLoaded } = useRecommendationFilterCounts();

  const filterCountMessage = useMemo(() => {
    if (!recommendationsLoaded) {
      return null;
    }

    if (recommendationsFiltered === undefined) {
      return `Showing ${recommendationsLoaded} patterns`;
    }

    return `Showing ${recommendationsFiltered} of ${recommendationsLoaded} patterns`;
  }, [recommendationsFiltered, recommendationsLoaded]);

  return filterCountMessage;
}

export const useCopyToClipboard = (text: string, duration = 2500): [boolean, () => void] => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const id = setTimeout(() => {
        setIsCopied(false);
      }, duration);
      return () => {
        clearTimeout(id);
      };
    }
    return () => void 0;
  }, [isCopied, duration]);

  return [
    isCopied,
    useCallback(() => {
      const didCopy = copy(text, { debug: true });
      setIsCopied(didCopy);
    }, [setIsCopied, text]),
  ];
};
