import React from 'react';

import { DisplayValue } from '@grafana/data';
import { PercentChangeColorMode } from '@grafana/schema';
import { BigValue, BigValueColorMode, BigValueJustifyMode, useTheme2 } from '@grafana/ui';

import { formatBytes } from '@/utils/formats';

type Props = {
  bytes: number;
  color: string;
  decimals: number;
  previousBytes?: number;
  title: string;
};

function getValueAndUnit(bytes: number, decimals: number) {
  if (bytes === 1) {
    return [String(bytes), 'byte'];
  }
  if (Math.abs(bytes) < 1024) {
    return [String(bytes), 'bytes'];
  }

  return formatBytes(bytes, decimals).split('  ');
}

export function BytesPanel({ bytes, color, decimals, previousBytes, title }: Props) {
  const theme = useTheme2();

  const [value, unit] = getValueAndUnit(bytes, decimals);

  const percentChange = previousBytes === undefined ? undefined : (bytes - previousBytes) / previousBytes;

  const displayValue: DisplayValue = {
    color: theme.visualization.getColorByName(color),
    numeric: bytes,
    percentChange,
    suffix: ' ' + unit,
    text: value,
    title,
  };

  const text = {
    titleSize: Number.parseInt(theme.spacing(2), 10),
    valueSize: Number.parseInt(theme.spacing(5), 10),
  };

  return (
    <BigValue
      value={displayValue}
      colorMode={BigValueColorMode.Value}
      justifyMode={BigValueJustifyMode.Auto}
      text={text}
      theme={theme}
      percentChangeColorMode={PercentChangeColorMode.Inverted}
    />
  );
}
