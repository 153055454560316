import React, { useState } from 'react';

import { keys as lodashKeys } from 'lodash';

import { Icon, LinkButton } from '@grafana/ui';

import { PatternRecommendation } from '@/api/types';
import { usePluginJsonData } from '@/hooks/plugin-hooks';
import { DEFAULT_DATASOURCE } from '@/utils/constants';
import { buildLogQueryFromPattern } from '@/utils/methods';

interface Props {
  recommendation: PatternRecommendation;
}

export const ExploreLinkButton = ({ recommendation }: Props) => {
  const { attribution, pattern } = recommendation;
  const serviceNames = lodashKeys(attribution);
  const [exploreLink, setExploreLink] = useState('');

  const { managedLogsDataSourceUid } = usePluginJsonData();
  const logsDataSourceUid = managedLogsDataSourceUid || DEFAULT_DATASOURCE;

  const onClick = () => {
    const queryStr = buildLogQueryFromPattern(pattern, serviceNames);

    const left = [
      encodeURIComponent(
        JSON.stringify({
          datasource: logsDataSourceUid,
          queries: [{ expr: queryStr }],
        })
      ),
    ];
    const link = `/explore?left=${left}`;
    setExploreLink(link);
  };

  return (
    <LinkButton
      variant="secondary"
      data-testid="explore-link-button"
      size="sm"
      href={exploreLink}
      target="_blank"
      onClick={onClick}
    >
      <Icon name="compass" />
    </LinkButton>
  );
};
