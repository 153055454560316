import React, { ComponentProps, useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { useFlag } from '@openfeature/react-sdk';
import { isEqual as lodashIsEqual } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Column, Icon, InteractiveTable, useStyles2 } from '@grafana/ui';

import { Bytes } from '../Bytes';
import { CurrentRateEditor } from '../CurrentRateEditor';
import { ExploreLinkButton } from '../ExploreLinkButton';
import { PatternSummary } from '../PatternSummary';
import { PatternRecommendation } from '@/api/types';
import { useFilter, useModifiedDropRates, useUserPermissions } from '@/hooks/context-hooks';
import { formatNumber } from '@/utils/formats';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    columnHeaderUpperText: css({
      fontSize: theme.typography.bodySmall.fontSize,
      position: 'absolute',
      top: theme.spacing(-1.5),
    }),
    getLockIconStyle: (locked: boolean) =>
      css({
        color: locked ? 'rgba(194, 109, 49, 1)' : undefined,
        marginTop: theme.spacing(0.4),
      }),
    multiLineHeader: css({
      display: 'inline-block',
      position: 'relative',
    }),
    noRows: css({
      color: theme.colors.text.secondary,
      fontStyle: 'italic',
      margin: `${theme.spacing(2)} auto 0`,
    }),
    // TODO: Remove when new_ui_post_ga feature flag is removed post GA
    oldPatternCell: css({
      textAlign: 'left',
      wordBreak: 'break-word',
    }),
    // idea from https://stackoverflow.com/a/44452722
    patternCell: css({
      '::after': {
        color: theme.colors.text.primary,
        content: 'attr(data-pattern)',
        left: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        right: 0,
        textOverflow: 'ellipsis',
        top: 0,
        whiteSpace: 'nowrap',
      },
      color: 'transparent',
      maxHeight: '1.5em',
      overflow: 'hidden',
      position: 'relative',
      wordBreak: 'break-word',
    }),
    readOnlyCurrentRate: css({
      width: '25%',
    }),
    readOnlyCurrentRateWrapper: css({
      display: 'flex',
      gap: 10,
    }),
  };
};

const headerTooltips: ComponentProps<typeof InteractiveTable>['headerTooltips'] = {
  configured_drop_rate: {
    content: 'Percentage of log lines currently being dropped',
    iconName: 'info-circle',
  },
  drop_rate_comparison: {
    content: 'Comparison between recommended drop rate and current (or modified) drop rate.',
    iconName: 'arrows-h',
  },
  queried_lines: {
    content: 'Total number of log lines returned by all queries which match this pattern.',
    iconName: 'info-circle',
  },
  recommended_drop_rate: {
    content: 'Recommended percent of log lines to drop',
    iconName: 'info-circle',
  },
  savings: {
    content: '15 day projected savings using configured rate.',
    iconName: 'info-circle',
  },
  volume: {
    content: 'Ingested volume for past 15 days.',
    iconName: 'info-circle',
  },
};

const Savings = ({ recommendation }: { recommendation: PatternRecommendation }) => {
  const { modifiedDropRates } = useModifiedDropRates();
  const modified = modifiedDropRates.get(recommendation.pattern);

  const effectiveDropRate = modified?.rate ?? recommendation.configured_drop_rate;
  const savings = Number(effectiveDropRate) > 0 ? recommendation.volume * (Number(effectiveDropRate) / 100) : 0;

  return <Bytes bytes={Math.floor(savings)} decimals={1} />;
};

type Styles = ReturnType<typeof getStyles>;

const getColumns = (
  styles: Styles,
  canApplyPatterns: boolean,
  newUIFlagEnabled: boolean,
  canAccessExplore: boolean
): Array<Column<PatternRecommendation>> => {
  return [
    {
      cell: ({ row: { original } }) => `${original.recommended_drop_rate}%`,
      disableGrow: true,
      header: 'Recommended',
      id: 'recommended_drop_rate',
      sortType: 'number',
    },
    {
      cell: ({ row: { original } }) => {
        const recommendation: PatternRecommendation = original;
        const { locked } = recommendation;
        return canApplyPatterns ? (
          <CurrentRateEditor recommendation={recommendation} />
        ) : (
          <div className={styles.readOnlyCurrentRateWrapper}>
            <div className={styles.readOnlyCurrentRate} data-testid="current-rate-text">
              {recommendation.configured_drop_rate}%
            </div>
            <Icon
              data-testid="curren-rate-icon"
              className={styles.getLockIconStyle(locked)}
              name={locked ? 'lock' : 'unlock'}
            />
          </div>
        );
      },
      disableGrow: true,
      header: 'Current (%)',
      id: 'configured_drop_rate',
      sortType: 'number',
    },
    {
      cell: ({ row: { original } }) => {
        return <Savings recommendation={original} />;
      },
      disableGrow: true,
      header: 'Savings',
      id: 'savings',
      sortType: 'number',
    },
    {
      cell: ({ row: { original } }) => {
        return <Bytes bytes={original.volume} decimals={1} />;
      },
      disableGrow: true,
      header: 'Volume',
      id: 'volume',
      sortType: 'number',
    },
    {
      cell: ({ row: { original } }) => {
        return <>{formatNumber((original.queried_lines / original.ingested_lines) * 100, 2)}</>;
      },
      disableGrow: true,
      header: 'Query : Ingest',
      id: 'queried_lines',
      sortType: 'number',
    },
    {
      cell: ({ row: { original } }) => (
        <div className={newUIFlagEnabled ? styles.patternCell : styles.oldPatternCell} data-pattern={original.pattern}>
          {original.pattern}
        </div>
      ),
      header: 'Pattern',
      id: 'pattern',
      sortType: 'string',
    },
    {
      cell: ({ row: { original } }) => canAccessExplore && <ExploreLinkButton recommendation={original} />,
      disableGrow: true,
      id: 'exploreLink',
    },
  ];
};

interface Props {
  tableData: PatternRecommendation[];
}

export function RecommendationsTable({ tableData }: Props) {
  const { filterCheck, isFiltered } = useFilter();
  const userPermissions = useUserPermissions();
  const { value: newUIFlagEnabled } = useFlag('new_ui_post_ga', false);
  const styles = useStyles2(getStyles);
  const [memoizedTableData, setMemoizedTableData] = useState<PatternRecommendation[]>([]);

  const columns = getColumns(
    styles,
    userPermissions.canApplyPatterns,
    newUIFlagEnabled,
    userPermissions.canAccessExplore
  );

  // When filter updates, triggers the uFuzzy
  const filteredData = useMemo(() => {
    if (filterCheck === null) {
      return tableData;
    } else {
      return tableData.filter((item) => filterCheck.has(item.pattern));
    }
  }, [tableData, filterCheck]);

  useEffect(() => {
    // By doing a deep compare of the filteredData and memoizedTableData, we avoid the table re-rendering sending the
    // user back to the first page when selecting an item (or anything that would cause the filteredData useMemo to fire.
    if (!lodashIsEqual(memoizedTableData, filteredData)) {
      setMemoizedTableData(filteredData);
    }
  }, [filteredData]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderExpandedRow = (recommendation: PatternRecommendation) => {
    return <PatternSummary recommendation={recommendation} />;
  };

  const noDataMessage = `No recommended patterns available ${isFiltered ? 'for the current filter' : ''}`;

  return (
    <>
      <InteractiveTable
        pageSize={15}
        columns={columns}
        data={memoizedTableData}
        headerTooltips={headerTooltips}
        renderExpandedRow={renderExpandedRow}
        getRowId={(rec: PatternRecommendation) => rec.pattern}
      />
      {!filteredData.length && (
        <div className={styles.noRows} data-testid="no-data-message">
          {noDataMessage}
        </div>
      )}
    </>
  );
}
