import { inRange as lodashInRange } from 'lodash';

import { DROP_RATE_UPPER_LIMIT_EXCLUSIVE } from '@/utils/constants';

export type RecommendationComparison = 'recommendedIsEqual' | 'recommendedIsGreater' | 'recommendedIsLess';

export function getComparisonWithRecommendation(
  recommendedRate: number,
  currentRate: number
): RecommendationComparison | undefined {
  if (Number.isNaN(currentRate) || !lodashInRange(currentRate, DROP_RATE_UPPER_LIMIT_EXCLUSIVE)) {
    return undefined;
  } else if (recommendedRate < currentRate) {
    return 'recommendedIsLess';
  } else if (recommendedRate > currentRate) {
    return 'recommendedIsGreater';
  } else {
    return 'recommendedIsEqual'; // This rate is "Just right."
  }
}
