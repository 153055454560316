import { getValueFormat } from '@grafana/data';

const bytesFormatter = getValueFormat('bytes');

export function formatBytes(bytes: number, decimals?: number) {
  const { suffix, text } = bytesFormatter(bytes, decimals);
  return `${text} ${suffix}`;
}

const bytesFormatterBase10 = getValueFormat('decbytes');

export function formatBytesBase10(bytes: number, decimals?: number) {
  const { suffix, text } = bytesFormatterBase10(bytes, decimals);
  return `${text} ${suffix}`;
}

const localeFormatter = getValueFormat('locale');

export function formatNumber(value: number, decimals?: number) {
  const { text } = localeFormatter(value, decimals);
  return text;
}
