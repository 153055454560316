import React, { createContext, useMemo, useState } from 'react';

import { SelectableValue } from '@grafana/data';

import { useRecommendations } from '@/hooks/api-hooks';
import { ModifiedDropRateMap } from '@/types';
import { filterRecommendations } from '@/utils/filter';

type AdaptiveLogsContextType = {
  filter: string;
  filterCheck: FilterCheck;
  modifiedDropRates: ModifiedDropRateMap;
  serviceNameFilter?: Array<SelectableValue<string>>;
  setFilter: (filter: string) => void;
  setModifiedDropRates: (ModifiedDropRate: ModifiedDropRateMap) => void;
  setServiceNameFilter: (serviceNames: Array<SelectableValue<string>>) => void;
};

export const AdaptiveLogsContext = createContext<AdaptiveLogsContextType>({} as AdaptiveLogsContextType);

interface Props {
  children: React.ReactNode;
}

export const AdaptiveLogsContextProvider = ({ children }: Props) => {
  const [filter, setFilter] = useState<string>('');
  const [serviceNameFilter, setServiceNameFilter] = useState<Array<SelectableValue<string>>>([]);
  const [modifiedDropRates, setModifiedDropRates] = useState<ModifiedDropRateMap>(new Map());

  const filterCheck = useFilterCheck(filter, serviceNameFilter);

  return (
    <AdaptiveLogsContext.Provider
      value={{
        filter,
        filterCheck,
        modifiedDropRates,
        serviceNameFilter,
        setFilter,
        setModifiedDropRates,
        setServiceNameFilter,
      }}
    >
      {children}
    </AdaptiveLogsContext.Provider>
  );
};

/**
 * Mimic the used api for Set.
 * If there is no filter this will be null
 */
type FilterCheck = {
  has: (pattern: string) => boolean;
  size: number;
} | null;

/**
 * @param filter set of patterns (keys) which should be omitted from the list
 */
function useFilterCheck(filter: string, serviceNameFilter: Array<SelectableValue<string>>): FilterCheck | null {
  const recommendations = useRecommendations();
  const items = recommendations.data?.items;

  const filterCheck = useMemo(() => {
    const serviceNameFilterSet = new Set(serviceNameFilter?.map((serviceName) => serviceName.value || ''));
    const acceptedIndices = filterRecommendations(items, filter, serviceNameFilterSet);

    // TODO, these indices could be re-used as a performance boost in ufuzzy in certain cases
    if (!items || acceptedIndices === null) {
      // Nothing is filtered out
      return null;
    }

    const filteredIn = new Set(acceptedIndices.map((index) => items[index].pattern));

    return filteredIn;
  }, [serviceNameFilter, items, filter]);

  return filterCheck;
}
