import React from 'react';

import { Tooltip } from '@grafana/ui';

import { formatBytes, formatBytesBase10 } from '@/utils/formats';

type Props = {
  bytes: number;
  decimals?: number;
};

export function Bytes({ bytes, decimals }: Props) {
  if (bytes === 1) {
    return <span>{bytes} byte</span>;
  }
  if (Math.abs(bytes) < 1024) {
    return <span>{bytes} bytes</span>;
  }

  const display = formatBytes(bytes, decimals);
  const tooltipBytes = Math.floor(bytes);

  const tooltip = `${formatBytesBase10(tooltipBytes, 3)}`;

  return (
    <Tooltip content={tooltip}>
      <span>{display}</span>
    </Tooltip>
  );
}
