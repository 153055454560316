import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { useFlag } from '@openfeature/react-sdk';
import { flatten as lodashFlatten, inRange as lodashInRange } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, InlineField, InlineFieldRow, Input, MultiSelect, useStyles2 } from '@grafana/ui';

import { FilterField } from './FilterField';
import { PatternModifiedCount } from './PatternModifiedCount';
import { ApplyModal } from '@/components/ApplyModal';
import { ContentBox } from '@/components/ContentBox';
import { SummaryModal } from '@/components/SummaryModal';
import { useRecommendations } from '@/hooks/api-hooks';
import { useFilter, useModifiedDropRates, useServiceNameFilter, useUserPermissions } from '@/hooks/context-hooks';
import { DROP_RATE_UPPER_LIMIT_EXCLUSIVE } from '@/utils/constants';

export const DIVISIONS = 5;
export const LARGE_SPAN = Math.floor(2 * (DIVISIONS / 3));
const SERVICE_NAME_FILTER_WIDTH = 45;

const getStyles = (theme: GrafanaTheme2, canApplyPatterns: boolean) => {
  return {
    base: css({
      display: 'grid',
      gridTemplateColumns: '3fr 1fr',
      marginTop: theme.spacing(1),
    }),
    /**
     * TODO: A PR https://github.com/grafana/grafana/pull/93858 for this is merged in the
     * grafana/grafana repo we will wait for the update on that and apply the
     * validationMessageHorizontalOverflow prop in the InlineField instead of having this css
     *  */
    errorFix: css`
      div[role='alert'] {
        position: fixed;
      }
    `,
    flexRowContent: css({
      alignItems: 'flex-end',
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(0.5),
      marginTop: canApplyPatterns ? theme.spacing(5) : undefined,
    }),
    largeSpan: css({
      flexGrow: 1,
      gridColumn: `span ${LARGE_SPAN}`,
    }),
    maxDropRate: css({
      display: 'grid',
      gridGap: theme.spacing(1),
    }),
    numberInput: css({
      input: {
        textAlign: 'right',
      },
    }),
    serviceNameFilterField: css({
      display: 'flex',
      gap: theme.spacing(1),
      marginTop: canApplyPatterns ? theme.spacing(5) : undefined,
    }),
  };
};

export const PageHeader = () => {
  const [summaryOpen, setSummaryOpen] = useState(false);
  const [applyOpen, setApplyOpen] = useState(false);
  // Will be `undefined` if the field is left blank
  const [maxRate, setMaxRate] = useState<number | undefined>(100);
  const userPermissions = useUserPermissions();
  const styles = useStyles2(getStyles, userPermissions.canApplyPatterns);
  const { value: newUIFlagEnabled } = useFlag('new_ui_post_ga', false);
  const { serviceNameFilter, setServiceNameFilter } = useServiceNameFilter();
  const { data: recommendations } = useRecommendations();

  // TODO this hook will be refactored
  const { modifiedDropRates, setModifiedDropRates } = useModifiedDropRates();
  const { filterCheck } = useFilter();

  const serviceNames = useMemo(() => {
    const attributions = recommendations?.items.map((item) => Object.keys(item.attribution));

    return [...new Set(lodashFlatten(attributions))].map((attribution) => {
      const name = attribution.substring(attribution.indexOf('"') + 1, attribution.lastIndexOf('"'));
      return {
        label: name,
        text: name,
        value: name,
      };
    });
  }, [recommendations?.items]);

  const maxRateInvalid = maxRate === undefined || !lodashInRange(maxRate, DROP_RATE_UPPER_LIMIT_EXCLUSIVE);

  const hasChange = useMemo(() => {
    if (!modifiedDropRates.size) {
      return false;
    }

    let canApply = false;
    modifiedDropRates.forEach((value) => {
      if (value.rate) {
        if (lodashInRange(Number(value.rate), DROP_RATE_UPPER_LIMIT_EXCLUSIVE)) {
          canApply = true;
        }
      }
      if (value.locked !== undefined) {
        canApply = true;
      }
    });

    return canApply;
  }, [modifiedDropRates]);

  const onSetMaxDropRate = () => {
    if (!maxRateInvalid) {
      const newModifiedDropRates = new Map(modifiedDropRates);

      recommendations.items.forEach(({ configured_drop_rate, locked, pattern, recommended_drop_rate }) => {
        if (filterCheck && !filterCheck.has(pattern)) {
          return;
        }

        const currentModification = modifiedDropRates.get(pattern);

        const modifiedToUnlock = currentModification?.locked === false;
        const currentLocked = currentModification?.locked || (locked && !modifiedToUnlock);

        if (currentLocked) {
          // For locked rates, we change nothing.
          return;
        }

        const rate = recommended_drop_rate > maxRate ? maxRate : recommended_drop_rate;

        if (rate !== configured_drop_rate) {
          newModifiedDropRates.set(pattern, { locked: false, rate: `${rate}` });
        }
      });

      setModifiedDropRates(newModifiedDropRates);
    }
  };

  return (
    <ContentBox className={styles.base}>
      <div className={styles.maxDropRate}>
        {userPermissions.canApplyPatterns && (
          <InlineFieldRow>
            <InlineField
              className={styles.errorFix}
              invalid={maxRateInvalid}
              error={'Drop percentage must be between 0% and 100%'}
              label="Maximum drop"
              tooltip="Any pattern with a drop % greater than this will be changed to this value"
            >
              <Input
                data-testid="maximum-rate"
                className={styles.numberInput}
                value={maxRate}
                maxLength={3}
                width={8}
                type="number"
                suffix={'%'}
                onChange={(event) => {
                  const value = event.currentTarget.value.trim();
                  if (value === '') {
                    setMaxRate(undefined);
                  } else {
                    setMaxRate(Number(value));
                  }
                }}
                onKeyDown={(e) => !maxRateInvalid && e.key === 'Enter' && onSetMaxDropRate()}
              />
            </InlineField>
            <Button fill={'outline'} onClick={onSetMaxDropRate} disabled={maxRateInvalid}>
              Set
            </Button>
          </InlineFieldRow>
        )}
        <div className={styles.serviceNameFilterField}>
          <InlineField label="Filter by service">
            <MultiSelect
              data-testid="service-filter"
              isSearchable
              isClearable
              width={SERVICE_NAME_FILTER_WIDTH}
              options={serviceNames}
              value={serviceNameFilter}
              placeholder="All services"
              onChange={(v) => {
                setServiceNameFilter(v);
              }}
            />
          </InlineField>
          {newUIFlagEnabled && <FilterField className={styles.largeSpan} />}
        </div>
      </div>
      <div className={styles.flexRowContent}>
        <PatternModifiedCount />
        <Button variant="secondary" onClick={() => setSummaryOpen(true)}>
          Preview
        </Button>
        {userPermissions.canApplyPatterns && (
          <>
            <Button
              variant="secondary"
              disabled={!hasChange}
              onClick={() => setModifiedDropRates(new Map())}
              tooltip={
                'This will restore all values to their previously saved values (except where they have been locked).'
              }
            >
              Revert
            </Button>
            <Button disabled={!hasChange} onClick={() => setApplyOpen(true)}>
              Apply drop rates
            </Button>
          </>
        )}
      </div>
      <SummaryModal
        recommendations={recommendations.items}
        isOpen={summaryOpen}
        onClose={() => setSummaryOpen(false)}
      />
      <ApplyModal
        isOpen={applyOpen}
        onClose={() => {
          setApplyOpen(false);
        }}
      />
    </ContentBox>
  );
};
