import React from 'react';

import { css } from '@emotion/css';
import { useFlag } from '@openfeature/react-sdk';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { PatternDisplay } from './PatternDisplay';
import { ServiceNameBreakDown } from './ServiceNameBreakDown';
import { PatternRecommendation } from '@/api/types';

interface Props {
  recommendation: PatternRecommendation;
}

const getStyles = (theme: GrafanaTheme2) => ({
  summaryWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    maxWidth: '100%',
  }),
});

export const PatternSummary = ({ recommendation }: Props) => {
  const { pattern } = recommendation;
  const styles = useStyles2(getStyles);
  const { value: newUIFlagEnabled } = useFlag('new_ui_post_ga', false);

  return (
    <div className={styles.summaryWrapper}>
      {newUIFlagEnabled && <PatternDisplay pattern={pattern} />}
      <ServiceNameBreakDown recommendation={recommendation} />
    </div>
  );
};
