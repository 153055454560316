import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, Stack, useStyles2 } from '@grafana/ui';

import { BytesPanel } from '../BytesPanel';

type Props = {
  ingestVolume: number;
  projectedVolume: number;
};

export function SavingsPreviewPanels({ ingestVolume, projectedVolume }: Props) {
  const styles = useStyles2(getStyles);

  const calculatedReduction = ingestVolume - projectedVolume;

  const differencePanel =
    calculatedReduction >= 0 ? (
      <BytesPanel bytes={calculatedReduction} decimals={2} color={'green'} title="Projected savings" />
    ) : (
      <BytesPanel bytes={-calculatedReduction} decimals={2} color={'red'} title="Projected increase" />
    );

  return (
    <div className={styles.panels}>
      <Stack direction={'row'} alignItems={'self-start'} justifyContent={'space-between'}>
        <BytesPanel bytes={ingestVolume} decimals={2} color={'orange'} title="Ingest volume" />
        <Icon className={styles.arrow} name="arrow-right" size="xxxl" />
        <BytesPanel
          bytes={projectedVolume}
          previousBytes={ingestVolume}
          decimals={2}
          color={'blue'}
          title="Projected volume"
        />
        {differencePanel}
      </Stack>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    arrow: css({ marginLeft: theme.spacing(-6), marginRight: theme.spacing(-6), marginTop: theme.spacing(4) }),
    panels: css({ marginTop: theme.spacing(3) }),
  };
}
